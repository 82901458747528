<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <!-- <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
      </el-row> -->
      <!-- 表格 -->
      <el-row>
         <el-col :span="5"><div class="herderTitle">家庭信息</div></el-col>
      </el-row>
      <table class="table">
        <tr>
          <td>家庭ID</td>
          <td>{{tableData.id}}</td>
          <td>家庭管理员ID</td>
          <td>{{tableData.user_id}}</td>
          <td>家庭成员人数</td>
          <td>{{tableData.count}}</td>
        </tr>
        <tr>
          <td>家庭名称</td>
          <td>{{tableData.family}}</td>
          
          <td>管理员名称</td>
          <td>{{tableData.name}}</td>
          <td>家庭地址</td>
          <td style="width:200px">{{tableData.address}}</td>
        </tr>
        <tr>
          <td>家庭电话</td>
          <td>{{tableData.phone}}</td>
          <td>家庭余额</td>
          <td>{{tableData.amount}}</td>
          <td>注册日期</td>
          <td>{{tableData.create_time}}</td>
        </tr>
        <tr>
          <td>家庭状态</td>
          <td><el-tag>{{tableData.status_text}}</el-tag></td>
           <td>消费金额</td>
          <td>{{tableData.monetary}}</td>
         
          <td>家庭分享码</td>
          <td><div class="image">
              <img
                :src="tableData.QRCode"
                alt=""
                width="100"
              />
            </div></td>
        </tr>
      </table>
            <el-row>
         <el-col :span="5"><div class="herderTitle">家庭成员列表</div></el-col>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="MemberList"
        tooltip-effect="dark"
        style="width: 100%"
        border
      >
       <el-table-column  align="center" prop="id" label="家庭ID"></el-table-column>
       <el-table-column  align="center" prop="user_id" label="用户ID"></el-table-column>
       <el-table-column  align="center" prop="name" label="用户名称"></el-table-column>
       <el-table-column  align="center" prop="monetary" label="消费金额"></el-table-column>
       <el-table-column  align="center" prop="status" label="当前状态"></el-table-column>
       <el-table-column  align="center" prop="create_time" label="注册日期"></el-table-column>
         <el-table-column label="操作"  align="center">
          <template slot-scope="scope">
           <el-tooltip class="item" effect="dark"  content="查看用户消费" placement="top-start">
            <el-button icon="iconfont icon-xiaofei01" type="primary" @click="familyCustomer(scope.row.id,scope.row.user_id)" ></el-button>
          </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[3, 5]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="1"
        style="margin-top:20px;"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {PostDetail} from "@/api/Family/detail.js";
import {postMemberList} from "@/api/Family/family.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      //家庭详情表格数据
      tableData: {},
      //家庭列表数据
      MemberList:[],
      familyId:'' //家庭ID
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //查看用户消费
    familyCustomer(id,userid){
         this.$router.push({
        path:"/family/consume",
        query:{
          id,
          userid
        }
      })  
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data={
        id:this.familyId,
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    console.log(data);
    this.MemberList=[]
    this.postMemberList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data={
        id:this.familyId,
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    console.log(data);
    this.MemberList=[]
    this.postMemberList(data);
    },
    /**-----------------网络请求生命周期函数--------------------- */
    //获取家庭详情网络请求
    async PostDetail(data){
        let res=await PostDetail(data);
        if(res.data.code==1){
          this.exp.num=res.data.data.total;
          this.tableData=res.data.data;
              console.log(res.data)
        }else{
            this.$message.error(res.data.message);
        } 
    },
     //获取家庭列表网络请求
    async postMemberList(data){
        let res=await postMemberList(data);
        if(res.data.code==1){
          this.MemberList=this.MemberList.concat(res.data.data.data);
              console.log(res.data)
        }else{
            this.$message.error(res.data.message);
        }
  },
  },
  
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
     this.familyId=this.$route.query.id
     var data={
      id:this.familyId,
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    this.postMemberList(data);
    this.PostDetail(data);
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.table{
  margin-bottom: 20px;
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
text-align: center;
border-spacing: 0;
border-collapse: collapse;
}
.table tr td{
  height: 60px;
border: 1px solid #ccc;
}
.herderTitle{
  font-size: 20px;
  margin-bottom: 10px;
}
.table tr td:nth-child(2n-1){
  font-size: 16px;
  color: black;
  background-color: #f5f5f5;
}
</style>